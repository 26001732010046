import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../Layout';
import { Box, Button, Grid, TextField, Typography, FormControlLabel, Checkbox  } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

export default function CreateAccount() {
  const { user } = useAuth();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isInternational, setIsInternational] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true); 
  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  const sanitizeName = (input) => {
    return input.replace(/['"$#@]/g, '').replace(/\s+/g, ' ').trim();
  };

  useEffect(() => {
    const fetchGeoData = async () => {
      const usStatesResponse = await fetch('/json/states.json');
      const usStatesData = await usStatesResponse.json();
      setStates(usStatesData);

      const countriesResponse = await fetch('/json/countries.json');
      const countriesData = await countriesResponse.json();
      setCountries(countriesData);
      
  };
  fetchGeoData(); 
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if the passwords match
    if (event.target.password.value !== confirmPassword) {
      setPasswordsMatch(false);
      toast.error('Passwords do not match');
      return; // Prevent form submission if passwords don't match
    } else {
      setPasswordsMatch(true);
    }

    const formData = new FormData(event.target);
    const customer = {};

    for (const [fieldName, fieldValue] of formData) {
      // loop through formData and sanitize excluding email and password
      if(fieldName==='email' || fieldName==='password'){
        customer[fieldName] = fieldValue;
      } else {
        customer[fieldName] = sanitizeName(fieldValue);
      }
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(customer),
        });
  
        if (response.ok) {
          // Account created successfully
          navigate('/login', { state: { successMessage: 'Account signup successful!' } });
        } else {
          // Handle the error response
          const errorData = await response.json();
          toast.error(errorData.message); // Display error message in a toast notification
        }
      } catch (error) {
        console.log('Error:', error);
      }
  };

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create LVA Member Account 
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Heading */}
            <Grid item xs={12} sm={6}>
              <TextField label="Name (30 character maximum)" name="name" required fullWidth inputProps={{maxLength:30}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Email Address" name="email" type="email" required fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField label="Password" name="password" type="password" required fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                required
                fullWidth
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField label="Mailing Address" name="address" required fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField label="City" name="city" required fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
            {!isInternational && (
              <TextField
                label="State"
                name="state"
                required
                fullWidth
                select
                SelectProps={{ native: true }}
              >
                <option value=""></option>
                {states.map((state) => (
                  <option key={state.code} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </TextField>
              )}
              {isInternational && (
                <TextField
                label="Country"
                name="country"
                required
                fullWidth
                select
                SelectProps={{ native: true }}
              >
                <option value=""></option>
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField label="Zip Code" name="zip" required fullWidth />
            </Grid>
            <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isInternational}
              onChange={() => setIsInternational(!isInternational)}
              name="isInternational"
            />
          }
          label="International"
        />
      </Grid>
            {!passwordsMatch && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error">
                  Passwords do not match.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  );
}
