import { Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

export default function CustomerInfoForm() {
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const { user } = useAuth();
  const [selectedState, setSelectedState] = useState(user ? user.user_state : '');
  const [selectedCountry, setSelectedCountry] = useState(user ? user.user_country : '');
  const [isInternational, setIsInternational] = useState(false);

  useEffect(() => {
    
    const fetchGeoData = async () => {
        const usStatesResponse = await fetch('/json/states.json');
        const usStatesData = await usStatesResponse.json();
        setStates(usStatesData);

        const countriesResponse = await fetch('/json/countries.json');
        const countriesData = await countriesResponse.json();
        setCountries(countriesData);
        
    };
    fetchGeoData(); 

  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField label="Name (30 character maximum)" name="name" defaultValue={user ? user.user_name : ''} required fullWidth inputProps={{maxLength:30}} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label="Mailing Address" name="address" defaultValue={user ? user.user_address : ''} required fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label="City" name="city" defaultValue={user ? user.user_city : ''} required fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
        {!isInternational && (
          <TextField
            label="State"
            name="state"
            required={!isInternational}
            fullWidth
            select
            SelectProps={{ native: true }}
            value={selectedState}
            onChange={(event) => setSelectedState(event.target.value)}
          >
            {!selectedState && <option value=""></option>}

            {states.map((state) => (
              <option key={state.code} value={state.name}>
                {state.name}
              </option>
            ))}
          </TextField>
        )}
        {isInternational && (
          <TextField
            label="Country"
            name="country"
            required={isInternational}
            fullWidth
            select
            SelectProps={{ native: true }}
            value={selectedCountry}
            onChange={(event) => setSelectedCountry(event.target.value)}
          >
            {!selectedCountry && <option value=""></option>}

            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Zip / Postal Code"
          name="zip"
          defaultValue={user ? user.user_zip_code : ''}
          required={!isInternational}
          fullWidth
          inputProps={{
            pattern: isInternational ? undefined : "\\d{5}",
            title: isInternational ? undefined : "Zip code must be 5 digits long",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Daytime Phone"
          name="phone"
          required
          fullWidth
          inputProps={{
            pattern: "\\d{10}", // Use regex to match exactly 10 digits
            title: "Phone number must be 10 digits long Ex. 8005553212", // Optional error message if pattern not matched
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label="Email Address" name="email" type="email" defaultValue={user ? user.user_email : ''} required fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isInternational}
              onChange={() => setIsInternational(!isInternational)}
              name="isInternational"
            />
          }
          label="International"
        />
      </Grid>
    </Grid>
  );
}
