import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import Layout from "../src/Layout";
import LoginPage from "./pages/login";
import ArchivesRequestPage from "./pages/archivesRequest";
import RequestPage from "./pages/requestLinks";
import OrdersPage from "./pages/orders";
import LookupPage from "./pages/orderLookup";
import ProfilePage from "./pages/profile";
import HomePage from "./pages/home";
import AccountCreationPage from "./pages/createAccount";
import ForgotPasswordPage from "./pages/forgotPassword";
import ResetPasswordPage from "./pages/resetPassword";
import PublishedMaterialsPage from "./pages/publishedMaterialsRequest";
import SpecialCollectionsPage from "./pages/specialCollectionsRequest";
import Maintenance from "./pages/maintenance"; // Import Maintenance component

// Flag to toggle maintenance mode
const maintenanceMode = false; // Set this to `false` when you're not in maintenance mode

function App() {
	if (maintenanceMode) {
		return <Maintenance />;
	}

	return (
		<>
			<Routes>
				<Route
					path="/"
					element={
						<Layout>
							<HomePage />
						</Layout>
					}
				></Route>
				<Route
					path="/orders"
					element={
						<RequireAuth>
							<OrdersPage />
						</RequireAuth>
					}
				/>
				<Route
					path="/profile"
					element={
						<RequireAuth>
							<ProfilePage />
						</RequireAuth>
					}
				/>
				<Route path="/lookup" element={<LookupPage />}></Route>
				<Route path="/request/" element={<RequestPage />}></Route>
				<Route
					path="/request/archives"
					element={<ArchivesRequestPage />}
				></Route>
				<Route
					path="/request/published-materials"
					element={<PublishedMaterialsPage />}
				></Route>
				<Route
					path="/request/special-collections"
					element={<SpecialCollectionsPage />}
				></Route>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/sign-up" element={<AccountCreationPage />} />
				<Route path="/forgot" element={<ForgotPasswordPage />} />
				<Route path="/reset" element={<ResetPasswordPage />} />
			</Routes>
		</>
	);
}

function RequireAuth({ children }) {
	const { user } = useAuth();
	let location = useLocation();

	if (!user) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}

export default App;
